import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql } from "gatsby"

import axios from 'axios';
import Layout from "../components/layout"
import ThemeContext from "../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from '../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'

const ErrorPage = ({ data }) => {

  console.log(data)
  const [jsonContent, setJsonContent] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
  var image = data.photos.edges[0];
  console.log(image);

  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {
  var _jsonContent =  response.data.content;
  console.log(_jsonContent);
  setJsonContent(_jsonContent);
  setIsLoaded(true);
});
}


  useEffect(() => {
    console.log('loaded')
      loadHtml('https://discover.lilleymansion.com/app/v1/page/1/error-404');
  },[]);

  useEffect(() => {
    setIsMobile(mobile);

    if(mobile){
      image = data.photos.edges[1];
      console.log(image);
    }
  },[]);




  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">
    <div id="page-wrapper">

{/*   <div className="imageFullWidth">
    {! isMobile  ?

      <GatsbyImage
       fluid={data.photos.edges[0].node.childImageSharp.fluid}
        alt={data.photos.edges[0].node.base.split("-").join(" ").split(".")[0]}
       image={getImage(data.photos.edges[0].node)}
       />
       :
       <GatsbyImage
        fluid={data.photos.edges[1].node.childImageSharp.fluid}
         alt={data.photos.edges[1].node.base.split("-").join(" ").split(".")[0]}
        image={getImage(data.photos.edges[1].node)}
        />
    }
    </div>
*/}
    <div className="container mt-5 pt-4 p-2 text-center">
    <h1 className="mt-4">Oh No!</h1>

    </div>

    <div
    style={{
      margin: `0`,
      padding: `20px`,
      background: `#fbfaf7`,
      color:`#111`
    }}
    >
    <div className="container mt-3 mb-5 px-md-5" dangerouslySetInnerHTML={{ __html: jsonContent }} />

    </div>
    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

ErrorPage.Layout = Layout

export default ErrorPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "history"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
